import React from 'react';
/* eslint-disable-next-line import/no-cycle */
import { useKeenSlider } from 'keen-slider/react';
import { useGridContext } from './context';
// import { useDraggable } from '../../../utils/use-draggable-scroll';
import SliderDots from './slider-dots';
import SliderButtons from './slider-buttons';
import useBreakpointValue from './use-breakpoint-value';
import SliderFloatedButtons from './slider-floated-buttons';
// import { useSplitColumn } from './split-column-context';
// import useResizeEvent from '../../../utils/use-resize-event';
// import Container from './container';
import SliderScrollbar from './slider-scrollbar';

type Row = HtmlPropsNoRef<HTMLDivElement> & Pick<CarouselContext, 'carousel'> & { overflowStyle?: React.CSSProperties, arrowStyle?: 'default' | 'floated' };

interface CarouselContext {
    carousel?: {
        [breakpoint: string]: { columnsPerCard: number, overflow?: boolean }
    }
    currentSlide: number
    slideLength: number
    setCurrentSlide: (slide: number, isForwardBack?: boolean) => void
}

const CarouselContext = React.createContext<CarouselContext>({} as CarouselContext);

export const useCarouselContext = (): CarouselContext | null => React.useContext(CarouselContext);

const Row = ({ carousel, overflowStyle = {}, arrowStyle = 'default', ...props }: Row): JSX.Element => {
    const { sortedBreakpoints: outerBreakpoints, desktopFirst } = useGridContext();

    const { slidesAtBreakpoint, gapAtBreakpoint } = useBreakpointValue({
        items: (() => {
            let lastCarouselBreakpoint;
            return outerBreakpoints.map((x) => {
                if (carousel?.[x[0]]) {
                    lastCarouselBreakpoint = carousel[x[0]];
                } else {
                    lastCarouselBreakpoint = { columnsPerCard: 4, overflow: false };
                }
                return {
                    query: x[1].query,
                    value: {
                        slidesAtBreakpoint: x[1].columns / lastCarouselBreakpoint.columnsPerCard,
                        overflowAtBreakpoint: lastCarouselBreakpoint.overflow,
                        gapAtBreakpoint: x[1].gap,
                    },
                };
            });
        })(),
        defaultValue: {
            slidesAtBreakpoint: 1,
            gapAtBreakpoint: 0,
        },
        desktopFirst,
    });

    const getRealSlide = (n: number) => slidesAtBreakpoint * Math.ceil(n / slidesAtBreakpoint);

    const [currentRenderSlide, setCurrentRenderSlide] = React.useState(0);
    const renderSlideRef = React.useRef(0);
    const ResizePlugin = (slider: any) => {
        const observer = new ResizeObserver(() => {
            slider.update();
        });

        slider.on('created', () => {
            observer.observe(slider.container);
        });
        slider.on('destroyed', () => {
            observer.unobserve(slider.container);
        });
    };
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        slides: {
            perView: () => slidesAtBreakpoint,
            spacing: gapAtBreakpoint,
        },
        breakpoints: {
            '(max-width: 767px)': {
                slides: {
                    perView: 1.2,
                    spacing: 16,
                    origin: 'auto',
                },
            },
        },
        slideChanged: (e) => {
            const t = e.track.details.rel;
            setCurrentRenderSlide(getRealSlide(t));
        },
        disabled: !carousel,
    }, [ResizePlugin]);

    const realSlideLength = (instanceRef?.current?.track?.details?.slides?.length || 0);

    const currentSlide = (currentRenderSlide || 0) / slidesAtBreakpoint;
    const slideLength = Math.ceil((instanceRef?.current?.track?.details?.slides?.length || 0) / slidesAtBreakpoint);

    const setCurrentSlide = (slide: number, isForwardBack?: boolean): void => {
        if (instanceRef && instanceRef.current) {
            const executor = (slide: number) => {
                if (instanceRef.current) {
                    const requested = getRealSlide(slide * slidesAtBreakpoint);
                    if (requested > realSlideLength || requested < 0) {
                        return;
                    }
                    if (requested + slidesAtBreakpoint > realSlideLength) {
                        instanceRef.current.moveToIdx(realSlideLength - slidesAtBreakpoint);
                        setCurrentRenderSlide(realSlideLength - 2);
                        renderSlideRef.current = realSlideLength - 2;
                    } else {
                        instanceRef.current.moveToIdx(requested);
                        setCurrentRenderSlide(requested);
                        renderSlideRef.current = requested;
                    }
                }
            };

            if (isForwardBack) {
                executor(Math.floor(currentSlide) + slide);
            } else {
                executor(slide);
            }
        }
    };

    const value = React.useMemo(() => (
        {
            carousel, currentSlide: Math.floor(currentSlide), slideLength, setCurrentSlide,
        }
    ), [carousel, currentSlide, setCurrentSlide]);

    const css = React.useMemo(() => {
        let adjustedCols = 0;
        let containerSize = {};
        let lastCarouselBreakpoint: { overflow?: boolean; columnsPerCard: number; } | null = null;
        return Object.fromEntries(outerBreakpoints.map(([selected, { query, gap, columns }]) => {
            if (carousel && carousel?.[selected]) {
                lastCarouselBreakpoint = carousel[selected];
            }
            if (carousel && lastCarouselBreakpoint) {
                if (lastCarouselBreakpoint.overflow || (carousel && carousel[selected]?.overflow)) {
                    if (adjustedCols === 0) {
                        adjustedCols = columns;
                    }
                    containerSize = {
                        display: 'flex',
                        paddingLeft: '32px',
                        paddingRight: '32px',
                        minWidth: 'unset',
                        '&::after': {
                            content: '""',
                            width: '1px',
                        },
                        '@media screen and (max-width: 1200px)': {
                            paddingLeft: '0',
                            paddingRight: '0',
                        },
                        '@media screen and (max-width: 767px)': {
                            '&::after': {
                                content: '""',
                                width: '1px',
                                paddingLeft: '32px',
                                paddingRight: '32px',
                            },
                        },
                        gridColumnGap: 'unset',
                        gridTemplateColumns: 'unset',
                    };
                } else {
                    containerSize = {
                        display: 'flex',
                        gridColumnGap: 'unset',
                        gridTemplateColumns: 'unset',
                    };
                }
            }
            if (!lastCarouselBreakpoint) {
                adjustedCols = columns;
            }

            return [query, {
                display: 'grid',
                gridTemplateColumns: `repeat(${adjustedCols}, 1fr)`,
                gridColumnGap: `${gap}px`,
                ...containerSize,
            }];
        }));
    }, [outerBreakpoints]);

    return (
        <>
            <div
                ref={sliderRef}
                key={`breakPointChange-${slidesAtBreakpoint}`}
                className={`__row ${carousel ? 'keen-slider' : ''}`}
                css={{
                    ...css,
                    width: '100%',
                    margin: 'auto',
                    position: 'relative',
                }}
                {...props}
            />
            {carousel && (
                <CarouselContext.Provider value={value}>
                    {arrowStyle === 'floated' && (
                        <div css={{
                            position: 'absolute',
                            top: 'calc(50% - 15px)',
                            width: '100%',
                            left: '0',
                            display: slideLength < 4 ? 'none' : 'flex',
                            '@media (max-width: 767px)': {
                                left: '25px',
                                width: 'calc(100% - 25px)',
                            },
                        }}
                        >
                            <SliderFloatedButtons />
                        </div>
                    )}
                    {arrowStyle === 'default' && (
                        <div css={{
                            width: '100%',
                            marginTop: '44px',
                            padding: '20px 0',
                            position: 'relative',
                            display: slideLength < 2 ? 'none' : 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '@media (max-width: 1200px)': {
                                marginTop: 0,
                            },
                        }}
                        >
                            <div
                                css={{
                                    display: 'none',
                                    width: '100%',
                                    '@media (max-width: 1200px)': {
                                        display: 'block',
                                    },
                                }}
                            >
                                <SliderScrollbar />
                            </div>
                            <div
                                css={{
                                    '@media (max-width: 1200px)': {
                                        display: 'none',
                                    },
                                }}
                            >
                                <SliderDots />
                            </div>
                            <div css={{
                                position: 'absolute',
                                right: '0',
                                '@media (max-width: 1200px)': {
                                    display: 'none',
                                },
                            }}
                            >
                                <SliderButtons />
                            </div>
                        </div>
                    )}
                </CarouselContext.Provider>
            )}
        </>
    );
};

export default Row;
